/* Firts Section Styles */
.firt-section {
  background-image: url("../../../images/first-banner.png");
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 120px 40px 0 40px;
  background-position-x: 50%;
  background-position-y: 5%;
}

.container-title h1 {
  font-weight: 500;
  color: #ffffff;
  font-size: 2.2rem;
}

.container-title h2 {
  font-weight: 300;
  color: #e5e5e5;
  font-size: 1.25rem;
}
.container-links {
  display: flex;
  gap: 25px;
  margin-top: 20px;
}
.container-links a {
  color: #ffffff;
  border: none;
  outline: 0;
  font-size: 1rem;
  cursor: pointer;
  font-weight: 300;
}
.container-links a:hover {
  text-decoration: none;
}
.links:hover {
  transform: scale(1.1);
}
.links img {
  width: 9px;
  margin: 5px;
}
.img-mobile {
  width: 16rem;
  margin-top: 30px;
}
@media screen and (min-width: 1000px) {
  .container-title h1 {
    font-size: 2.8rem;
  }
  .container-title h2 {
    font-size: 1.6rem;
  }
  .container-links a {
    font-size: 1.1rem;
  }
  .img-mobile {
    width: 18rem;
    margin-top: 40px;
  }
}

/*Second Section Styles */
.second-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 30px 50px 30px 50px;
}
.second-section h2 {
  font-weight: 700;
  font-size: 1.6rem;
  color: #000000;
}
.second-section h3 {
  font-weight: 300;
  font-size: 1rem;
  color: #838282;
  margin-top: 15px;
}
.second-section img {
  max-width: 7.5rem;
  margin-top: 30px;
}
.container-btn-home {
  margin-top: 35px;
}
@media screen and (min-width: 1000px) {
  .second-section h2 {
    font-size: 2.3rem;
  }
  .second-section h3 {
    padding: 0 400px 0 400px;
    font-size: 1.3rem;
  }
  .second-section img {
    max-width: 8.2rem;
  }
}
/* Third Section Styles */
.third-section {
  background-image: url("../../../images/second-banner.png");
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 90px 20px 50px 20px;
  background-position: 70%;
}
.third-section h2 {
  font-size: 2.8rem;
  font-weight: 700;
  color: #ffffff;
  line-height: 35px;
}
.third-section p {
  font-size: 1.5rem;
  font-weight: 300;
  color: #e5e5e5;
}
.container-btn-store {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.container-btn-store img {
  max-width: 7rem;
}

.container-btn-store img:hover {
  transform: scale(1.1);
}

@media screen and (min-width: 1000px) {
  .third-section {
    padding-top: 120px;
  }
  .third-section h2 {
    font-size: 3.2rem;
  }
  .third-section p {
    font-size: 1.7rem;
  }
  .container-btn-store {
    margin-top: 5px;
    gap: 30px;
    margin-bottom: 5px;
  }
  .container-btn-store img {
    max-width: 9rem;
  }
}
/* Fourth Section Styles */
.container-sections {
  padding: 50px 40px 0 40px;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#porqueone {
  scroll-margin-top: 70px;
}
.mg {
  margin-top: 20px;
}
.mobiles {
  width: 20rem;
  margin-top: 20px;
}
/* Component Text Styles */
.component-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.component-text h2 {
  font-size: 1.9rem;
  font-weight: 600;
  color: #000000;
}
.component-text p {
  font-size: 1rem;
  font-weight: 300;
  color: #3f3f3f;;
  line-height: initial;
  margin-bottom: 10px;
}
.component-text h3 {
  font-size: 1.4rem;
  font-weight: 400;
  color: #000000;
}
.component-links {
  display: flex;
  gap: 25px;
}
.component-links a {
  color: #20abcb;
  border: none;
  outline: 0;
  font-size: 1rem;
  cursor: pointer;
  font-weight: 400;
}
.component-links a:hover {
  text-decoration: none;
  color: #20abcb;
}

@media screen and (min-width: 1200px) {
  .component-text h2 {
    font-size: 2rem;
  }
  .component-text p {
    font-size: 1.1rem;
  }
  .component-text h3 {
    font-size: 1.5rem;
  }
  .component-links a {
    font-size: 1.1rem;
  }
}

/* Fifth Section Styles */
.imgs-fifth-section {
  display: flex;
  gap: 25px;
  justify-content: center;
  margin-left: -135px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.imgs-fifth-section img {
  width: 15rem;
}
.readjustment {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 60px;
  align-items: center;
  text-align: center;
}
.readjustment h3 {
  font-size: 0.8rem;
  color: #000000;
  font-weight: 400;
}
.readjustment p {
  background-color: #20abcb;
  box-shadow: -30px -5px 35px 0px #00000040;
  padding: 5px 18px 5px 18px;
  color: #ffffff;
  border-radius: 10px;
  font-size: 0.8rem;
  font-weight: 300;
}
.readjustment-training {
  margin-bottom: 60px;
  margin-top: 40px;
  text-align: center;
}
.readjustment-training h3{
  font-size: 1.5rem;
  color: #000000;
  font-weight: 400;
}
.readjustment-training p{
  background-color: #20abcb;
  box-shadow: -30px -5px 35px 0px #00000040;
  padding: 7px 30px 7px 30px;
  color: #ffffff;
  border-radius: 10px;
  font-size: 1.4rem;
  font-weight: 300;
}
@media screen and (min-width: 1100px) {
  .imgs-fifth-section {
    gap: 50px;
    margin-left: -103px;
  }
  .imgs-fifth-section img {
    width: 16rem;
  }
  .readjustment h3 {
    font-size: 1rem;
  }
  .readjustment p {
    padding: 5px 20px 5px 20px;
    color: #ffffff;
    font-size: 1rem;
  }
}
/* Sith Section Styles*/
.background-img {
  background-image: url("../../../images/third-banner.png");
  background-size: cover;
  background-repeat: no-repeat;
  padding: 50px 40px 40px 40px;
  background-position: 45%;
}
.img-sith-section {
  display: flex;
  justify-content: center;
  margin-left: 170px;
  margin-top: 50px;
}
.img-sith-section img {
  width: 12rem;
}

@media screen and (min-width: 1100px) {
  .img-sith-section img {
    width: 12rem;
  }
}

/*Seven Section Styles*/
.seventh-section {
  background: linear-gradient(#f5f5f54d, #764def4d);
  padding: 50px 20px 40px 20px;
}
.section-cards-home {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 20px;
  gap: 20px;
}
.seventh-section h6 {
  text-align: center;
  margin-top: 30px;
  font-weight: 500;
  font-size: 1rem;
  padding: 0 35px 0 35px;
  color: #000000;
}
/* Cards Home Styles*/
.home-cards {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 20px;
  border: 1px solid #c4c4c4;
  padding: 20px 30px 20px 30px;
  background-color: #ffffff;
}
.row-items {
  display: flex;
  justify-content: space-between;
}
.flag-blue {
  background-image: url("../../../images/flag-blue.png");
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
}
.row-items h2 {
  font-size: 1.4rem;
  color: #000000;
  font-weight: 700;
}
.row-items h3 {
  font-size: 0.7rem;
  color: #ffffff;
  font-weight: 700;
  margin: 5px 22px 5px 22px;
}
.home-cards h4 {
  font-size: 1rem;
  color: #3f3f3f;
  font-weight: 700;
  margin-top: 5px;
}
.home-cards h5 {
  font-size: 0.8rem;
  color: #838282;
  font-weight: 400;
}

/* Nineth Part Styles */
.nineth-part {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
}
.nineth-part h2 {
  color: #000000;
  font-weight: bold;
  font-size: 2rem;
}
.accordion {
  width: 70%;
  cursor: pointer;
  border-left: 1px solid rgb(5, 5, 5);
  border-right: 1px solid rgb(5, 5, 5);
  border-top: 1px solid rgb(5, 5, 5);
  margin-top: 20px;
  margin-bottom: 60px;
}
.item {
  border-bottom: 1px solid rgb(5, 5, 5);
  padding: 15px 20px 10px 30px;
}
.title-accordion {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.title-accordion h2 {
  font-size: 1rem;
  font-weight: bold;
  color: #000000;
}
.title-accordion span {
  font-size: 1rem;
}
.content {
  color: #000000;
  max-height: 0;
  overflow: hidden;
  transition: all 0.5s cubic-bezier(0, 1, 0, 1);
}
.content.show {
  height: auto;
  max-height: 9999px;
  transition: all 0.5s cubic-bezier(1, 0, 1, 0);
}
.item p {
  margin-top: 10px;
  color: #363636;
}
.item span {
  color: #363636;
}
.list-items {
  display: flex;
  flex-direction: column;
}
.item img {
  width: 1rem;
}
@media screen and (min-width: 250px) and (max-width: 767px) {
  .accordion {
    width: 90%;
  }
}
.pd {
  padding-bottom: 40px;
}
.white {
  padding: 50px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.list-secciton {
  padding-bottom: 70px;
}
