/* Waiting List Styles */
.container-wait-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 25px 40px 10px 40px;
}
.container-wait-list h1 {
  font-size: 2.2rem;
  background: linear-gradient(#20abcb, #9747ff);
  color: transparent;
  -webkit-background-clip: text;
  font-weight: 700;
  margin-top: 80px;
}
.container-wait-list h2 {
  font-weight: 700;
  font-size: 0.85rem;
  color: #575656;
  margin-top: 5px;
}
.alert-garmin {
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: center;
  margin-top: 5px;
}
.alert-garmin img {
  width: 22px;
  height: 22px;
}
.alert-garmin h3 {
  color: #575656;
  font-weight: 400;
  font-size: 0.85rem;
  margin: 0;
}
.container-form-wait-list {
  margin-top: 10px;
}
.container-form-wait-list label {
  color: #575656;
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 0.2rem;
}
.container-form-wait-list input {
  text-align: center;
  color: #b8b8b8;
  font-weight: 300;
}
.container-form-wait-list input::placeholder {
  color: #b8b8b8;
}

.container-register {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 150px;
  margin-bottom: 150px;
}
.container-register img {
  max-width: 4rem;
}
.container-register h1 {
  font-weight: bold;
  margin-top: 20px;
  color: #000;
  font-size: 2rem;
}
.container-register h3 {
  font-size: 1.4rem;
}
.error-email {
  color: red;
}
.container-btn-register a {
  background: rgb(69, 218, 255);
  border-radius: 30px;
  margin-top: 40px;
  padding-left: 30px;
  padding-right: 30px;
  border: none;
}
.text-system {
  color: #575656;
  font-size: 1rem;
  font-weight: 700;
  margin-top: 20px;
  margin-bottom: 10px;
}
.container-ios {
  display: flex;
  justify-content: center;
  gap: 60px;
}
.container-android {
  display: flex;
  justify-content: center;
  gap: 30px;
  margin-bottom: 20px;
}
.container-ios label,
.container-android label {
  font-weight: 400;
}
.container-ios input,
.container-android input {
  accent-color: #319fb9;
  width: 1.2rem;
  height: 1.2rem;
}
.container-text {
  margin-top: 20px;
  padding: 0 10px 0 10px;
}
.container-text h6 {
  font-weight: 700;
  font-size: 0.85rem;
  color: #575656;
  line-height: 18px;
}
.container-text p {
  font-weight: 400;
  font-size: 0.78rem;
  color: #3f3f3f;
  line-height: 13px;
  margin-top: 15px;
}

@media screen and (min-width: 600px){
  .container-wait-list {
    padding: 30px 100px 20px 100px;
  }

}
@media screen and (min-width: 990px){
  .container-wait-list {
    padding: 30px 200px 20px 200px;
  }

}
@media screen and (min-width: 1150px) {
  .container-wait-list {
    padding: 40px 300px 30px 300px;
  }
}
 @media screen and (min-width: 1250px) {
  .container-wait-list {
    padding: 40px 350px 30px 350px;
  }
}