/* Header Styles */
.container-nav {
  background-color: #000000f8;
  padding: 0vw;
  display: flex;
  justify-content: center;
  position: sticky;
  padding: 8px;
}

.container-nav button {
  border: none;
  outline: none;
  float: left;
}

.link-logo {
  padding: 0vw;
}

.link-logo img {
  max-width: 6vw;
  height: auto;
  margin-left: 1rem;
}

.container-ul {
  justify-content: center;
}

.container-ul a {
  color: #ffffff !important;
  font-size: 0.85rem;
  font-weight: 300;
}

.modal-beta {
  background-color: #00000080;
  display: flex;
  justify-content: center;
  position: fixed;
  width: 100%;
  text-align: center;
  align-items: center;
  padding: 5px;
  z-index: 1;
}
.modal-beta p {
  margin: 0;
  color: #ffffff;
  font-size: 1rem;
  font-weight: 300;
}
.modal-beta a {
  color: #ffffff;
  text-decoration: underline;
  font-weight: 400;
}
.container-logo {
  width: 0%;
  z-index: 2;
}
.container-logo button {
  background: transparent;
}
.desplegable {
  left: auto;
  background-color: #000000;
  top: auto;
  border: none;
}
.desplegable a {
  font-size: 0.85rem;
}
.dropdown-item:hover {
  background-color: #161515;
}
@media screen and (max-width: 991px) {
  .container-nav nav {
    padding: 2vw;
  }

  .container-btn {
    width: 35%;
  }

  .nav-img {
    width: 2rem;
    margin-left: 12px;
  }

  .container-logo {
    width: 65%;
  }

  .link-logo img {
    max-width: 4.5rem;
  }

  .container-ul {
    padding: 3vw;
  }
  .container-ul a {
    font-size: 0.8rem;
  }
  .modal-beta p {
    font-size: 0.8rem;
  }
  .desplegable {
    background-color: transparent;
  }
}
@media screen and (min-width: 1100px) {
  .container-ul ul {
    display: flex;
    gap: 20px;
  }
}
@media screen and (max-width: 355px) {
  .modal-beta {
    width: 98%;
  }
}
@media all and (min-width: 992px) {
  .navbar .nav-item .dropdown-menu {
    display: none;
  }
  .navbar .nav-item:hover .nav-link {
    color: #fff;
  }
  .navbar .nav-item:hover .dropdown-menu {
    display: block;
  }
  .navbar .nav-item .dropdown-menu {
    margin-top: 0;
  }
}
