.container-start {
  padding: 35px;
  height: 95vh;
  background: linear-gradient(#f5f5f535, #20abcb35, #9747ff35);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.container-start img {
  width: 6rem;
}
.container-start h2 {
  font-weight: 700;
  font-size: 2.2rem;
  color: #000000;
}
.container-start p {
  font-weight: 500;
  font-size: 1rem;
  color: #000000;
}
.container-start a {
  border-radius: 50px;
  font-size: 0.8rem;
  font-weight: 700;
  background: #fff;
  border: #fff;
  color: #000;
  padding: 10px 35px 10px 35px;
}
