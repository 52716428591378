.container-garmin {
    padding: 30px;
  }
  .titules-garmin {
    margin-top: 30px;
  }
  .titules-garmin h3 {
    font-size: 0.8rem;
    color: #3f3f3f;
    font-weight: 500;
  }
  .titules-garmin h1 {
    font-size: 1.5rem;
    color: #3f3f3f;
    font-weight: 700;
    margin-top: 12px;
    margin-bottom: 15px;
  }
  .dotted-garmin {
    border-top: 1px dotted #3f3f3f85;
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
  }
  .titules-garmin span {
    font-size: 0.72rem;
    color: #3f3f3f;
    font-weight: 400;
    padding: 5px;
  }
  .titules-garmin h5 {
    font-size: 1rem;
    color: #575656;
    font-weight: 500;
    margin-top: 20px;
    line-height: 22px;
  }
  .devices-table {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .container-devices {
    margin-top: 25px;
  }
  .container-devices h6 {
    font-size: 0.96rem;
    color: #3f3f3f;
    font-weight: 700;
    text-align: center;
  }
  .container-box {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #b8b8b8;
    border-bottom: 1px solid #b8b8b8;
    padding: 7px;
  }
  .box-garmin {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .box-garmin p {
    margin: 0;
  }
  .logo-devices {
    width: 1.8rem;
  }
  .name-devices {
    font-size: 0.8rem;
    color: #000000;
    font-weight: 400;
  }
  .span-devices{
    font-size: 0.75rem;
    color: #838282;
    font-weight: 400;
  }
  .span-devices:hover{
    color: #20ABCB;
    transform: scale(1.1);
  }
  .status-devices{
  width: 1rem;
  }
  @media screen and (min-width: 992px) {
    .container-garmin {
      padding: 30px 120px; 
    }
  }