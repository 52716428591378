.footer {
  background-color: #000000;
  border: 1px solid #000000;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 15px 20px 0px;
}

.info-footer p {
  color: #e5e5e5;
  font-size: 0.7rem;
  margin: 0;
}
.info-footer span {
  color: #838282;
  font-size: 0.7rem;
}
.rules {
  display: flex;
  justify-content: center;
}
.rules p {
  color: #e5e5e5;
  font-size: 0.7rem;
}
.rules a {
  color: #e5e5e5;
  font-size: 0.7rem;
}
