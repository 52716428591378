/* Styles Planes Premiun */
.subheader {
  text-align: center;
  margin-top: 40px;
}
.dotted {
  border-top: 1px dotted #3f3f3f;
  max-width: 90%;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.subheader h1 {
  font-size: 1.5rem;
  font-weight: 700;
  color: #3f3f3f;
}
.subheader h2 {
  font-size: 0.8rem;
  color: #3f3f3f;
  margin: 0;
}
.section-cards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 20px;
  gap: 20px;
}
.container-cards {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 20px;
  border: 1px solid #c4c4c4;
  padding: 10px 20px 10px 20px;
}
.container-cards:hover {
  box-shadow: 1px 4px 4px rgba(0, 0, 0, 0.25);
  cursor: pointer;
}
.item1 {
  display: flex;
  gap: 20px;
}
.item1 h3 {
  font-size: 1.4rem;
  color: #000000;
  font-weight: 700;
}
.item2 h3 {
  font-size: 0.7rem;
  margin: 5px 20px 5px 20px;
  color: white;
  font-weight: 700;
}
.container-cards h4 {
  font-size: 1rem;
  color: #3f3f3f;
  font-weight: 700;
  margin-top: 5px;
}
.container-cards h5 {
  font-size: 0.8rem;
  color: #838282;
  font-weight: 400;
}
.row-card {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.item2 {
  background-image: url("../../../images/flag.png");
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
}
.item3 input {
  accent-color: #9747ff;
  width: 1.5rem;
  height: 1.5rem;
  background-color: #9747ff;
}

.container-btn-secondary {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 50px;
}

@media screen and (min-width: 800px) {
  .dotted {
    max-width: 50%;
  }
  .subheader {
    margin-top: 60px;
  }
  .subheader h1 {
    font-size: 2.1rem;
  }
  .subheader h2 {
    font-size: 1rem;
  }
  .text-terms {
    font-size: 1rem;
  }
  .section-cards {
    margin-top: 80px;
  }
  .container-btn-secondary {
    margin-top: 60px;
    margin-bottom: 80px;
  }
  .container-btn-secondary {
    margin-top: 60px;
    margin-bottom: 80px;
  }
}
/* Styles transacctions */
.payments {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
}
.container-payment {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 20px 0px 20px;
  border: 1px solid #c4c4c4;
  border-radius: 20px;
  padding: 25px 18px 10px 18px;
  color: #838282;
}
.container-check img {
  width: 3rem;
}

.container-transactions {
  padding: 10px;
  text-align: center;
}
.container-transactions h1 {
  font-size: 1.2rem;
  font-weight: bolder;
  color: #000;
}
.container-transactions h3 {
  font-size: 0.9rem;
  color: #838282;
}
/* Styles Manage Subscription */
.container-cards-suscription {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
  padding: 20px 0 20px 0;
}
.card-suscription {
  width: 90%;
  border-radius: 20px;
  border: 1px solid #c4c4c4;
  padding: 20px;
}
.card-suscription:hover {
  box-shadow: 1px 4px 4px rgba(0, 0, 0, 0.25);
}
.card-up {
  text-align: start;
}
.card-up h4 {
  font-size: 1.3rem;
  font-weight: 700;
  margin-top: 5px;
  margin-bottom: 0;
}
.card-up h5 {
  font-size: 1rem;
  font-weight: 700;
  color: #575656;
}
.card-up p {
  font-size: 0.8rem;
  font-weight: 400;
  margin-top: 0;
  line-height: 16px;
  color: #838282;
}
.card-down {
  padding: 15px 10px 0 10px;
  display: flex;
  flex-direction: column;
  text-align: center;
}
.card a {
  justify-content: center;
}
.btn-active {
  border-radius: 12px;
  background-color: #9747ff;
  border: 1px solid #9747ff;
  color: #ffffff;
  font-weight: 300;
  font-size: 1rem;
  margin-bottom: 10px;
}
.btn-active:hover {
  background-color: #862aff;
  border: 1px solid #862aff;
  color: #ffffff;
}
.card-down span {
  color: #5f5f5f;
  font-size: 0.8rem;
  margin-bottom: 10px;
}
.btn-deactivated {
  border-radius: 12px;
  background-color: transparent;
  border: 1px solid #c4c4c4;
  color: #c4c4c4;
  font-weight: 300;
  font-size: 1rem;
  margin-top: 10px;
}
.btn-deactivated:hover {
  color: #5c5c5c;
}
.img-cards img {
  width: 4.5rem;
}
.card img:hover {
  transform: none;
}
.number-card p {
  margin-top: 20px;
  font-size: 0.9rem;
}
.number-card span {
  color: #575656;
}
.expiration-date p {
  margin-top: 10px;
}
.img-pdf {
  display: flex;
  margin-top: 20px;
  gap: 10px;
}
.img-pdf img {
  width: 3.5rem;
}
@media screen and (min-width: 800px) {
  .card-suscription {
    width: 30%;
  }
  .container-cards-suscription {
    padding: 40px;
  }
}
