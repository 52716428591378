.reset-password {
    margin: 10vh 40px 10vh 40px;
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  .reset-password label {
    font-size: 1rem;
    font-weight: 400;
    color: #575656;;
    margin-top: 20px;
  }
  .reset-password input {
    text-align: center;
    color: #b8b8b8;
    font-weight: 300;
  }
  .btn-reset-password {
    margin-top: 40px;
  }

  @media screen and (min-width: 800px) {
    .reset-password {
      margin: 20vh 35vw 20vh 35vw;
    }
    .reset-password label {
      font-size: 1.2rem;
    }
  }