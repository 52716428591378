.container-sections-one {
  padding: 120px 30px 30px 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.background {
  background-color: #f5f5f5;
}
.component-text-one h2 {
  font-size: 1.9rem;
  font-weight: 600;
  color: #000000;
  text-align: center;
  margin: 0;
}
.component-text-one p {
  font-size: 1.3rem;
  font-weight: 400;
  color: #000000;
  text-align: center;
  margin: 2px;
}
.small-titule {
  font-size: 1.2rem;
  font-weight: 400;
  color: #3f3f3f;
  text-align: center;
}
.section-information span {
  font-style: italic;
  font-size: 1.2rem;
  font-weight: 400;
  color: #3f3f3f;
}
.component-text-one h6 {
  font-size: 0.8rem;
  font-weight: 400;
  color: #3f3f3f;
  margin-bottom: 10px;
  text-align: center;
  padding-right: 11px;
  padding-left: 11px;
}
.text-img-one img {
  width: 16rem;
  margin-top: 20px;
}
.text-img-one {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.container-paragraph h3 {
  margin-bottom: 30px;
}
.container-paragraph {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  padding: 30px;
}
.paragraph p {
  font-size: 0.8rem;
  color: #3f3f3f;
  font-weight: 400;
}
.btn-mg {
  margin-top: 50px;
}
.links {
  margin-bottom: 20px;
}
.mobile-running {
  margin-left: 30px;
  margin-top: 20px;
}
.mobile-running img {
  width: 13rem;
}
.num-running {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 30px;
  color: #20abcb;
}
.num-running h1{
  font-size: 9rem;
  font-weight: 700;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.num-running h4 {
  font-size: 5rem;
  font-weight: 700;
}
.num-running h2 {
  position: relative;
  bottom: 23px;
  margin: 0;
  font-size: 2.8rem;
  letter-spacing: 1px;
  font-weight: 400;
}
.num-running h3 {
  font-size: 1.35rem;
  font-weight: 400;
  position: relative;
  bottom: 22px;
}


@media screen and (min-width: 1000px) {
  .component-text-one h6 {
    font-size: 1rem;
  }
  .paragraph {
    width: 50%;
  }
  .paragraph p {
    font-size: 0.9rem;
  }
  .pd-one {
    padding-bottom: 80px;
  }
}
