.container-alerts {
  height: 90vh;
}
.container-alert {
  display: flex;
  justify-content: center;
}
.content-alert {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 20px 0px 20px;
  border: 1px solid #c4c4c4;
  border-radius: 20px;
  padding: 15px 18px 10px 18px;
  color: #838282;
  margin-top: 10rem;
}
.container-img-alert img {
  width: 2.9rem;
}
.container-text-alert {
  padding: 10px;
  text-align: center;
}
.container-text-alert h1 {
  font-size: 1.2rem;
  font-weight: bolder;
  color: #000;
}
.container-text-alert h3 {
  font-size: 0.8rem;
  color: #838282;
}
