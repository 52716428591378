.container-list {
  display: flex;
  gap: 20px;
  margin-top: 30px;
  margin-bottom: 60px;
  flex-wrap: wrap;
}
.item-list {
  display: flex;
  align-items: center;
  gap: 10px;
}
.item-list img {
  width: 1.9rem;
}
.text-list {
  display: flex;
  flex-direction: column;
  gap: 3px;
}
.text-list h4 {
  margin: 0;
  font-size: 1rem;
  color: #3f3f3f;
  font-weight: 700;
}
.text-list p {
  margin: 0;
  font-size: 0.7rem;
  line-height: initial;
  color: #3f3f3f;
  font-weight: 400;
}
.container-devices-home {
  padding: 50px 30px;
  background-color: #f5f5f5;
}
.text-list span {
  font-size: 0.7rem;
  color: #20abcb;
  font-weight: 700;
}

@media screen and (min-width: 620px) {
  .container-devices-home {
    padding: 50px 170px;
  }
  .item-list {
    max-width: 40%;
  }
  .container-list {
    justify-content: center;
  }
}
@media screen and (min-width: 1000px) {
  .item-list {
    max-width: 23%;
    flex-direction: column;
    text-align: center;
  }
  .container-list {
    justify-content: center;
    margin-top: 50px;
  }
  .text-list {
    gap: 10px;
    margin-top: 10px;
  }
  .text-list h4 {
    font-size: 1.1rem;
  }
  .text-list p, .text-list span {
    font-size: 0.8rem;
  }
  .item-list img {
    width: 3rem;
  }
}
