.first-btn{
    text-decoration: none;
    border-radius: 50px;
    font-size: 0.9rem;
    font-weight: 700;
    background-color: #20ABCB;
    border: 1px solid #20ABCB;
    color: #ffffff;
    padding: 8px 30px 8px 30px;
    cursor: pointer;
}
.first-btn:hover {
    transform: scale(1.1);
    text-decoration: none;
    color: #ffffff;
  }
  .first-btn:not(:disabled):not(.disabled):active {
    background-color: #20accbee;
    border-color: #20accbee;
  }
  .first-btn:focus {
    outline: none;
  }
.second-btn {
    text-decoration: none;
    border-radius: 50px;
    font-size: 0.9rem;
    font-weight: 700;
    background: #9747ff;
    border:1px solid #9747ff;
    color: #fff;
    padding: 8px 30px 8px 30px;
    cursor: pointer;
  }
  .second-btn:hover {
    transform: scale(1.1);
    background: #9747ff;
  }
  .second-btn:not(:disabled):not(.disabled):active {
    background-color: #9747ffec;
    border-color: #9747ff;;
  }
  .second-btn:focus {
    outline: none;
  }
  .third-btn {
    text-decoration: none;
    border-radius: 50px;
    font-size: 0.9rem;
    font-weight: 700;
    background: #000000;
    border:1px solid #000000;
    color: #fff;
    padding: 8px 30px 8px 30px;
    cursor: pointer;
  }
  .third-btn:hover {
    transform: scale(1.1);
    background: #000000;
    text-decoration: none;
    color: #fff;
  }
  .third-btn:not(:disabled):not(.disabled):active {
    background-color: #000000e1;
    border-color: #000000e1;
  }
  .third-btn:focus {
    outline: none;
  }
  .container-btn-alert {
    display: flex;
    justify-content: center;
    margin-top: 50px;
  }