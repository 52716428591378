.container-about {
  background-color: #f5f5f5;
}
.container-about-banner {
  background-image: url("../../../images/img-about.png");
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 90vh;
  margin-left: -30px;
}
.logo-about {
  width: 6rem;
}
.text-logo {
  font-weight: 500;
  color: #ffffff;
  margin-top: 20px;
  font-size: 2.5rem;
}
.text-about {
  padding: 35px;
}
.text-about h1 {
  font-weight: 800;
  font-size: 2.6rem;
  background: linear-gradient(#9747ff, #20abcb);
  color: transparent;
  -webkit-background-clip: text;
}
.text-about h3 {
  font-weight: 700;
  font-size: 0.95rem;
  color: #3f3f3f;
  margin-top: 20px;
  line-height: 22px;
}
.text-about p {
  font-weight: 400;
  font-size: 0.95rem;
  color: #3f3f3f;
  margin-top: 20px;
  margin-bottom: 0;
  line-height: 22px;
}
.card-profile {
  margin: 15px 35px 15px 35px;
  padding: 20px;
  background: #fff;
  border-radius: 15px;
  box-shadow: 0 4px 4px 0 #00000025;
}
.card-profile:hover {
  box-shadow: 1px 4px 4px rgb(0 0 0 / 25%);
}
.container-profile {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}
.container-profile img {
  border-radius: 50px;
  height: 4rem;
}
.text-profile {
  margin-top: 10px;
}
.text-profile h3 {
  font-weight: 600;
  font-size: 1rem;
  color: #3f3f3f;
  line-height: 0;
}
.text-profile h5 {
  font-weight: 400;
  font-size: 0.8rem;
  color: #20abcb;
}
.text-profile h4 {
  font-weight: 400;
  font-size: 1rem;
  color: #5f5f5f;
}
.descrition-card p {
  font-weight: 400;
  font-size: 0.8rem;
  color: #5f5f5f;
  margin-top: 20px;
  padding: 0 20px 0 0px;
}
.container-max {
  display: flex;
  flex-direction: row-reverse;
}
.container-max span {
  color: #fff;
  background-color: #3f3f3f;
  width: 1.2rem;
  font-size: 0.8rem;
  border-radius: 15px;
  text-align: center;
}
.text-color {
  padding: 20px;
  border-radius: 15px;
  margin: 15px 35px 15px 35px;
}
.text-color:hover {
  box-shadow: 1px 4px 4px rgb(0 0 0 / 25%);
}
.text-one {
  background: linear-gradient(#9747ff, #20abcb);
}
.text-container-color {
  height: 55vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.text-container-color h2 {
  color: #fff;
  font-weight: 800;
  font-size: 2.6rem;
}
.container-max-white {
  display: flex;
  flex-direction: row-reverse;
}
.container-max-white span {
  color: #838282;
  background-color: #fff;
  width: 1.2rem;
  font-size: 0.8rem;
  border-radius: 15px;
  text-align: center;
}
.text-two {
  background-color: #000000;
}
.text-three {
  background: linear-gradient(#838282, #000000);
}
@media screen and (min-width: 700px) {
  .logo-about {
    width: 7rem;
  }
  .text-logo {
    font-size: 3rem;
  }
  .text-about {
    padding-left: 150px;
    padding-right: 150px;
  }
  .card-profile, .text-color {
    margin-left: 150px;
    margin-right: 150px;
  }
}
@media screen and (min-width: 1100px) {
  .container-about-banner {
    height: 85vh;
    background-position-y: 10%;
  }
  .text-about {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-left: 350px;
    padding-right: 350px;
  }
  .card-profile, .text-color {
    margin-left: 10px;
    margin-right: 10px;
   max-width: 25%;
  }
  .cards-flex{
    padding-left: 100px ;
    padding-right: 100px ;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
}