.container-pacing {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 50px 20px 30px 20px;
}
.texts-pacing h1 {
  font-size: 1.6rem;
  color: #000000;
  font-weight: 600;
}
.texts-pacing h2 {
  font-size: 1.15rem;
  color: #5f5f5f;
  font-weight: 400;
}
.texts-pacing h4 {
  font-size: 0.85rem;
  color: #20abcb;
  font-weight: 400;
}
.calculator-form {
  border: 1px solid #0808084a;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0px 0px 7px 0px #0808084a;
  margin-top: 20px;
}
.calculator-pacing {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 10px 0 10px 0;
}
.calculator-select {
  display: inline-flex;
  gap: 10px;
  align-items: self-end;
  text-align: center;
  justify-content: center;
}
.calculator-select p,
span {
  font-weight: 400;
  font-size: 0.77rem;
  margin: 0;
  color: #5f5f5f;
}
.calculator-select input {
  max-width: 75px;
  text-align: center;
  margin: 0;
  font-weight: 700;
  font-size: 0.8rem;
  color: #5f5f5f;
}
.calculator-select select {
  font-weight: 700;
  font-size: 0.8rem;
  color: #5f5f5f;
}
.container-select {
  display: flex;
  flex-direction: column;
}
.container-pacing h6 {
  font-size: 0.5rem;
  color: #5f5f5f;
  font-weight: 400;
}
.btn-calculate {
  text-decoration: none;
  border-radius: 50px;
  font-size: 0.8rem;
  font-weight: 700;
  background-color: #20abcb;
  border: 1px solid #20abcb;
  color: #ffffff;
  padding: 5px 20px 5px 20px;
  margin-top: 15px;
}
.btn-calculate:hover {
  transform: scale(1.1);
  text-decoration: none;
  color: #ffffff;
}
.btn-calculate:not(:disabled):not(.disabled):active {
  background-color: #20accbee;
  border-color: #20accbee;
}
.btn-calculate:focus {
  outline: none;
}
.container-21k {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.container-21k img {
  width: 24rem;
}
.time {
  text-align: center;
}
.time h5 {
  font-size: 1rem;
  font-weight: 400;
  color: #20abcb;
  margin-bottom: 0;
}
.time h2 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #20abcb;
}
.table-calculate,
.table-bordered td,
.table-bordered th {
  width: 15rem;
  border: 1px solid #5f5f5fa6;
}
.table-calculate thead {
  text-align: center;
  color: #5f5f5f;
  font-weight: 400;
  font-size: 0.9rem;
  background: #f1f1f1;
}
.table-calculate tbody {
  text-align: center;
  color: #5f5f5f;
  font-weight: 400;
  font-size: 0.7rem;
}
.yellow {
  background-color: #ffd71080;
}
.red {
  background-color: #ff181880;
}
.green {
  background-color: #4ccb2080;
}
.blue {
  background-color: #20abcb80;
}
.container-conditions {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 25px;
}
.container-conditions h6 {
  font-size: 0.9rem;
  color: #20abcb;
  font-weight: 400;
}
.container-conditions span {
  color: #5f5f5f;
  margin-top: 10px;
  font-size: 0.5rem;
  font-weight: 400;
}
.conditions {
  width: 21rem;
  margin-left: 20px;
}
.container-p {
  padding: 0;
  text-align: start;
}
.container-p p {
  font-size: 0.69rem;
  color: #5f5f5f;
  font-weight: 400;
  margin: 0 0 5px 0px;
}
.error-calculate {
  color: rgba(255, 65, 65, 0.884);
  font-size: 0.9rem;
  margin-top: 10px;
}
.end-text {
  color: #20abcb;
  font-size: 1.2;
  font-weight: 400;
  margin-top: 20px;
}
